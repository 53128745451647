import { useApi } from "@/composition/useApi.js"
export default{
    state: () => ({
        userInfo: null,
        isShowModal : false,
    }),
    mutations:{
      setUserInfo(state, payload){
        state.userInfo = payload
      },
      setUserAvatarUrl(state, url){
        state.userInfo.avatarUrl = url
      },
      setShowModal(state, bool){
        state.isShowModal = bool
      },
    },
    actions: {
      getUserInfo({commit}){
        // запрос info о текущем пользавателе
        const {api} = useApi()
        api.get(`/auth/user`)
          .then((res)=>{
            if(res.status === 200){
              commit('setUserInfo', res.data)
              // запрос на получение аватара пользователя
              api.get(`/users/${res.data.id}/avatar`, {'responseType': 'blob'})
              .then((r)=>{
                if(r.status === 200){
                  const urlCreator = window.URL || window.webkitURL;
                  commit('setUserAvatarUrl', urlCreator.createObjectURL(new Blob([r.data])))
                }
              })
            }
          })
      },
    },
  }