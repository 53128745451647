<template>
    <div class="usertooltip">
      <div class="usertooltip__ico">
        <img :src="userInfo.avatarUrl" alt="avatar">
      </div>
      <div class="usertooltip__info">
        <div class="usertooltip__name">
          {{ userInfo.login }}
        </div>
        <div class="usertooltip__company">
          {{ userInfo.folder?.name }}
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props:{
      userInfo:{
        type: Object,
        required: true
      }
    }
  }
</script>
<style lang="scss" scoped>
.usertooltip {
  visibility: hidden;
  width: fit-content;
  padding: 15px 50px 15px 15px;
  background: #fff;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  position: absolute;
  z-index: 1;
  top: 150%;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  &__ico{
    width: 100%;
    height: 100%;
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  &__name{
    font-family: "Vela Sans SemiBold";
    color: black;
    white-space: nowrap;
  }
  &__company{
    white-space: nowrap;
  }
}
.usertooltip::before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 1rem;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f7f9ff transparent;
}
</style>