
import axios from 'axios'
import config from '/config.json'

let tokenCSRF = null;
export function useApi() {

    const updateCSRF = () => {
        return api.get('/csrf')
            .then((r) => {
                localStorage.setItem('tokenCSRF',r.data.token)
                return tokenCSRF = r.data.token
            });
    }

    const _axios = (method, route, data, options = {}) => {
        return new Promise((resolve, reject) => {
        try {
            axios({
                method,
                url: `${config.API_URL}${route}`,
                data,
                withCredentials: true,
                headers: { ...(tokenCSRF ? { 'csrf-token': tokenCSRF } : {}) },
                ...options
            })
                .then(resolve)
                .catch((r) => {
                    switch(r.response.status) {
                        case 401:
                            window.location.href = config.AUTH_URL
                        break;

                        case 403:
                            if(r.response.data.message.indexOf('CSRF') > -1)
                                return updateCSRF().then(() => { _axios(method, route, data).then(resolve) })
                            
                            reject(r);
                        break;

                        default:
                            reject(r);
                        break;
                    }
                });
            } catch(error) {
                console.log(error);
            }
        })
    }

    //Factory
    const api = {
        get: (route,options) => _axios('get', route, null, options),
        post: (route, data, options) => _axios('post', route, data, options),
        put: (route, data, options) => _axios('put', route, data, options),
        delete: (route, data, options) => _axios('delete', route, data, options),
        patch: (route, data, options) => _axios('patch', route, data, options),
    };

    return {
        api,
        updateCSRF,
        tokenCSRF
    }
}