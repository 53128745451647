<template>
    <div class="wrapper">
      <Header/>
      <router-view/>
    </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { useStore } from 'vuex';
export default {
  components:{ Header },
  setup(){
    const store = useStore()
    store.dispatch('getUserInfo')
    return{}
  }
}
</script>

<style>
@import '@/assets/styles/normalize/normalize.css';
@font-face {
  font-family: "Vela Sans";
  src: url("assets/fonts/VelaSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
#app {
  height: 100%;
   font-family: "Vela Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-style: normal;
  color: #777C9A;
}
.wrapper{
  height: 100%;
}
</style>
