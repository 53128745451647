// import axios from 'axios'
import { useApi } from "@/composition/useApi.js"
import config from '/config.json'
export default{
    state: ()=>({
        tokenCSRF : null,
        isAuth: true
    }),
    mutations:{
        setTokenCSRF(state, token){
            state.tokenCSRF = token
        },
        clearTokenCSRF(state){
            state.tokenCSRF = null
        },
        setIsAuth(state,bool){
            state.isAuth = bool
        }
    },
    actions:{
        authUser(){
            const { api } = useApi();
            return api.get(`/auth/user`)
                .catch((r)=>{
                    if(r.response.status == 401)
                        window.location.href = config.AUTH_URL
                })
        },
        logoutUser({commit}){
            const { api } = useApi();
            return api.post('/auth/logout', null)
                .then(()=>{
                    commit('clearTokenCSRF')
                    window.location.href = config.AUTH_URL  
                })
        },
    }
}